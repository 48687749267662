import React from 'react';

import { isMobile } from 'react-device-detect';

const App = () => {
  React.useEffect(() => {
    // parse location
    const { search } = global.location;
    if (!search) return;

    const split = search.split('&');
    if (split.length < 2) return;

    let access = split[0];
    let type = split[1];
    let mode = split[2] || 'mode=production';

    if (access[0] === '?') access = access.substring(1);

    // redirect to web.nimbuslearning.com
    const isTesting = mode.split('=')[1] !== 'production';
    const redirectUrl = `https://web${
      isTesting ? '-stage' : ''
    }.nimbuslearning.com/login?t=${access.split('=')[1]}&${type}`;

    if (!isMobile) {
      window.location.href = redirectUrl;
    } else {
      setTimeout(() => {
        window.location.href = redirectUrl;
      }, 1000)
    }

    setTimeout(() => {
      window.postMessage(
        JSON.stringify({
          access: access.split('=')[1],
          type: type.split('=')[1],
        }),
      );
    }, 100);
  }, []);

  return (
    <div style={{ padding: 24 }}>
      <p>
        Login Successful! Please close this window if it doesn't automatically close.
      </p>
    </div>
  );
};

export default App;
